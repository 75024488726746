<template>
    <div class="week-calendar">
        <div class="button-container">
            <div class="button-left-part-wrapper">
                <BasicButton
                    :color="getNumberOfPeriods >= 35 ? 'grey' : 'orange'"
                    class="button-add"
                    @click="modalCreate"
                    :disabled="getNumberOfPeriods >= 35"
                    ><span>
                        {{ $t("global.modal.create-time-range") }}
                    </span>
                </BasicButton>
                <basic-button
                    v-if="$route.params.scheduleId !== '0'"
                    color="blue"
                    @click="razVerif()"
                    class="button-reset only-desktop"
                >
                    <span class="text">
                        {{ $t("vigik.custom.reset-to-zero") }}
                    </span>
                </basic-button>
            </div>
            <div class="button-right-part-wrapper">
                <basic-button
                    color="orange"
                    @click="saveVerif"
                    :disabled="isDeepEqual && !isReset"
                    class="button-save"
                >
                    <span class="text">
                        {{ $t("button.validate") }}
                    </span>
                </basic-button>
                <basic-button
                    color="blue"
                    @click="cancelVerif"
                    :disabled="isDeepEqual && !isReset"
                    class="button-cancel"
                    ><span>
                        {{ $t("button.cancel") }}
                    </span>
                </basic-button>
            </div>
            <div class="mobile-right-part">
                <basic-button
                    color="grey-light"
                    @click="displayMobileMenu = !displayMobileMenu"
                >
                    <i class="fas fa-ellipsis-v" />
                </basic-button>
                <div class="mobile-menu" v-if="displayMobileMenu">
                    <p @click="modalCreate">
                        {{ $t("global.modal.create-time-range") }}
                    </p>
                    <p
                        @click="
                            $route.params.scheduleId !== '0' ? razVerif() : null
                        "
                        :style="
                            $route.params.scheduleId !== '0'
                                ? ''
                                : 'color: #ababab'
                        "
                    >
                        {{ $t("vigik.custom.reset-to-zero") }}
                    </p>
                </div>
            </div>
        </div>
        <div class="calendar-week">
            <div
                :class="displayDays.length > 0 && 'padding-border'"
                class="hours"
            >
                <div
                    v-for="hour in hours"
                    :key="`hou_${hour.name}`"
                    class="hour"
                >
                    <div v-if="hour.name">
                        <span class="button-container">
                            {{ formatHour(hour.name) }}
                        </span>
                    </div>
                </div>
            </div>
            <div :class="readonly && 'readonly'" class="calendar-cells">
                <div class="days">
                    <div
                        v-for="day in days"
                        :key="`day_${day.value}`"
                        class="day"
                    >
                        <span
                            class="only-mobile"
                            :class="
                                displayDays.findIndex((e) => e === day.value) >=
                                    0 && 'selected-day'
                            "
                            @click="displayDay(day.value)"
                        >
                            {{ $t(`${day.name}`).slice(0, 1) }}
                        </span>
                        <span class="no-mobile">
                            {{ $t(`${day.name}`) }}
                        </span>
                        <i class="fas fa-copy" @click="copyDay(day.value)" />
                    </div>
                </div>
                <div class="calendar-days">
                    <div
                        v-for="day in getDays"
                        :key="`calendar-day_${day.value}`"
                        class="column-days"
                        :id="`day-${day.value}`"
                    >
                        <div
                            :id="`${day.value}-${index_hour}`"
                            v-for="(hour, index_hour) in getHours(true)"
                            :key="`half_hour_${index_hour}`"
                            :data-value="hour.time"
                            class="half-hour"
                            :class="
                                countPeriods(day.value) === maxTimeRange &&
                                'disabled'
                            "
                            @mousedown="
                                !readonly &&
                                    countPeriods(day.value) < maxTimeRange &&
                                    startSelectCell($event)
                            "
                            @mouseup="
                                !readonly &&
                                    countPeriods(day.value) < maxTimeRange &&
                                    !getForceCopy[
                                        parseInt($event.target.id.slice(0, 1))
                                    ] &&
                                    stopSelectCell($event)
                            "
                            @mouseover="
                                !readonly &&
                                    countPeriods(day.value) < maxTimeRange &&
                                    !getForceCopy[
                                        parseInt($event.target.id.slice(0, 1))
                                    ] &&
                                    selectCell($event)
                            "
                        >
                            <div
                                :id="`${day.value}-${index_hour}-${index_timer}`"
                                v-for="(timer, index_timer) in periods"
                                :key="`time_range_${index_timer}`"
                                class="time-range"
                            >
                                <div
                                    v-if="
                                        displayCalendar &&
                                        checkSelectedCell(
                                            timer,
                                            day,
                                            index_hour
                                        )
                                    "
                                    class="selected-cell"
                                    @click="
                                        openModalEdit(
                                            day.value,
                                            $t(`${day.name}`),
                                            index_timer,
                                            timer,
                                            true
                                        )
                                    "
                                    :style="{
                                        height: `${getHeightTimer(
                                            day.value,
                                            hour.time,
                                            timer.start,
                                            timer.end
                                        )}px`,
                                    }"
                                    :ref="`selected-cell-${day.value}`"
                                >
                                    <!-- <div
                                        class="hour-details"
                                        :style="{
                                            left: `${
                                                getStartPosition().width + 1
                                            }px`,
                                        }"
                                    >
                                        <div>
                                            {{
                                                timer.accurateStart
                                                    ? timer.accurateStart
                                                    : formatHourCell(hour.time)
                                            }}
                                        </div>
                                        <div>-</div>
                                        <div>
                                            {{
                                                timer.accurateEnd
                                                    ? timer.accurateEnd
                                                    : formatHourCell(
                                                          hours[
                                                              parseInt(
                                                                  timer.end
                                                              ) + 1
                                                          ]?.time || "00:00:00"
                                                      )
                                            }}
                                        </div>
                                    </div> -->
                                    <div class="overlay">
                                        <div
                                            v-if="
                                                !readonly &&
                                                getHeightTimer(
                                                    day.value,
                                                    hour.time,
                                                    timer.start,
                                                    timer.end
                                                ) > 15
                                            "
                                            @click="
                                                openModalEdit(
                                                    day.value,
                                                    $t(`${day.name}`),
                                                    index_timer,
                                                    timer,
                                                    true
                                                )
                                            "
                                            class="actions"
                                        >
                                            <p>
                                                {{
                                                    timer.accurateStart
                                                        ? timer.accurateStart
                                                        : formatHourCell(
                                                              hour.time
                                                          )
                                                }}
                                                -
                                                {{
                                                    timer.accurateEnd
                                                        ? timer.accurateEnd
                                                        : formatHourCell(
                                                              hours[
                                                                  parseInt(
                                                                      timer.end
                                                                  ) + 1
                                                              ]?.time ||
                                                                  "00:00:00"
                                                          )
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BasicButton from "@/components/basic/BasicButton.vue"
import { useToast } from "vue-toastification"
import { deepEqual } from "@/helpers/commonFunctions"

const toast = useToast()

export default {
    name: "WeekCalendar",
    components: {
        BasicButton,
    },
    props: {
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        days: {
            type: Array,
            required: true,
        },
        maxTimeRange: {
            type: Number,
            required: true,
        },
        maxDifferentDay: {
            type: Number,
            required: true,
        },
        initialValue: {
            type: Array,
            required: false,
        },
        isPersonnalised: {
            type: Boolean,
            required: false,
            default: false,
        },
        isReset: {
            type: Boolean,
            required: false,
            default: false,
        },
        isDeepEqual: {
            type: Boolean,
            required: false,
            default: false,
        },
        scheduleId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            selectedDay: null,
            selectedHour: null,
            periods: [],
            displayCalendar: true,
            openIndex: null,
            displayDays: [],
            differentDay: [],
            displayMobileMenu: false,
        }
    },
    computed: {
        // Retourne les jours à afficher
        getDays() {
            if (this.displayDays.length > 0) {
                return this.days.filter(
                    (day) =>
                        this.displayDays.findIndex((e) => e === day.value) >= 0
                )
            } else {
                return this.days
            }
        },
        // Bloque la capacité de créer de nouvelles périodes si le nombre de jour différent max est atteint
        getForceCopy() {
            let status = []

            for (let i = 0; i < this.differentDay.length; i++) {
                status.push(this.differentDay[i].length >= 5)
            }

            return status
        },
        // Formate le retour pour une utilisation par d'autres api
        formatReturn() {
            let data = []
            this.days.forEach((e) => {
                data.push({
                    weekday: e.value,
                    intervals: [],
                })
            })
            this.periods.forEach((period) => {
                const index = data.findIndex((e) => e.weekday === period.day)
                data[index]["intervals"].push({
                    start: period.accurateStart
                        ? `${period.accurateStart}:00`
                        : this.hours[parseInt(period.start)]?.time.slice(-8),
                    end: period.accurateEnd
                        ? `${period.accurateEnd}:00`
                        : this.hours[parseInt(period.end) + 1]?.time.slice(
                              -8
                          ) || "24:00:00",
                })
            })
            return data
        },
        // use to known if schedule can be reset
        canBeReset() {
            return (
                (this.scheduleId !== "0" || !this.isDeepEqual) && !this.isReset
            )
        },

        getNumberOfPeriods() {
            return this.periods.length
        },
    },
    mounted() {
        this.initialValue.forEach((day) => {
            let values = []
            values[day.weekday] = true
            day.timeslot.forEach((time) => {
                this.createTimeRange(
                    time.start.substring(0, 5),
                    time.end.substring(0, 5),
                    values
                )
            })
        })
        this.$emit("update:initialDisplayArray", {
            periods: this.formatReturn,
        })
    },
    watch: {
        periods() {
            this.getDifferentDay()
        },
        initialValue: {
            handler() {
                this.periods = []
                this.initialValue.forEach((day) => {
                    let values = []
                    values[day.weekday] = true
                    day.timeslot.forEach((time) => {
                        this.createTimeRange(
                            time.start.substring(0, 5),
                            time.end.substring(0, 5),
                            values
                        )
                    })
                })
            },
        },
    },
    created() {
        this.getHours(false)
        // Listener lors du resize de la fenêtre
        window.addEventListener("resize", this.resizeWindow)
    },
    unmounted() {
        // destruction du listener
        window.removeEventListener("resize", this.resizeWindow)
    },
    methods: {
        // Retoune toutes les heures disponible par tranche de 30 minutes
        getHours(onlyDisplay) {
            const start = 0
            const end = 23
            const hours = []

            // add index for debug
            for (let i = start; i <= end; i++) {
                if (i < 10) {
                    hours.push({
                        i: hours.length,
                        name: `0${i}h`,
                        time: `0${i}:00:00`,
                    })
                    hours.push({
                        i: hours.length,
                        name: "",
                        time: `0${i}:30:00`,
                    })
                } else {
                    hours.push({
                        i: hours.length,
                        name: `${i}h`,
                        time: `0${i}:00:00`,
                    })
                    hours.push({
                        i: hours.length,
                        name: "",
                        time: `0${i}:30:00`,
                    })
                }
            }
            if (onlyDisplay === false) {
                hours.push({ i: hours.length, name: `24h`, time: `024:00:00` })
                this.hours = hours
            }
            return hours
        },
        getDifferentDay() {
            this.differentDay = []
            const filterPeriods = []
            this.days.forEach((value, index) => {
                filterPeriods[index] = []
                this.periods.forEach((e) => {
                    if (e.day === index) {
                        filterPeriods[index].push({
                            start: e.start,
                            end: e.end,
                            accurateStart: e.accurateStart,
                            accurateEnd: e.accurateEnd,
                        })
                    }
                })
            })

            filterPeriods.forEach((periods, day) => {
                if (periods.length > 0) {
                    let find = false
                    this.differentDay.forEach((diff, index) => {
                        if (deepEqual(periods, diff)) {
                            find = true
                            this.differentDay[day] = diff
                        }
                    })
                    if (!find) {
                        this.differentDay[day] = periods
                    }
                } else {
                    this.differentDay[day] = []
                }
            })
            this.$emit("change", {
                periods: this.formatReturn,
            })
        },
        countPeriods(day) {
            let count = 0
            this.periods.forEach((period) => {
                if (period.day === day) {
                    count++
                }
            })
            return count
        },
        formatHour(time, forceMobile = false) {
            if (forceMobile) return time
            else return `${time}00`
        },
        formatHourCell(hour) {
            let retour = ""
            let test = hour.split(":")
            if (parseInt(test[0]) > 9) {
                retour = parseInt(test[0])
            } else {
                retour = `0${parseInt(test[0])}`
            }
            if (parseInt(test[1]) > 9) {
                retour += `:${parseInt(test[1])}`
            } else {
                retour += `:0${parseInt(test[1])}`
            }
            return retour
        },
        startSelectCell(e) {
            /**
             * For all $refs which are in e.target.id.slice(0, 1) column, add .let-column-focus class
             *
             * @param {Object} e - event object
             * @return {void}
             */

            for (let i = 0; i < this.days.length; i++) {
                if (
                    parseInt(e.target.id.slice(0, 1)) === this.days[i].value &&
                    this.$refs[`selected-cell-${this.days[i].value}`]
                ) {
                    for (
                        let j = 0;
                        j <
                        this.$refs[`selected-cell-${this.days[i].value}`]
                            .length;
                        j++
                    ) {
                        this.$refs[`selected-cell-${this.days[i].value}`][
                            j
                        ].classList.add("let-column-focus")
                    }
                }
            }

            /**
             * If e.target.id exist, add .drag class to e.target.id element and set selectedDay and selectedHour to have the start information
             *
             * @param {Object} e - event object
             * @return {void}
             */

            if (
                this.getForceCopy[parseInt(e.target.id.slice(0, 1))] &&
                e.target.id
            ) {
                for (let i = 0; i < this.days.length; i++) {
                    if (
                        parseInt(e.target.id.slice(0, 1)) ===
                            this.days[i].value &&
                        this.$refs[`selected-cell-${this.days[i].value}`]
                    ) {
                        for (
                            let j = 0;
                            j <
                            this.$refs[`selected-cell-${this.days[i].value}`]
                                .length;
                            j++
                        ) {
                            this.$refs[`selected-cell-${this.days[i].value}`][
                                j
                            ].classList.remove("let-column-focus")
                        }
                    }
                }
                this.openMaxHourModal()
            } else {
                if (e.target.id) {
                    const element = document.getElementById(e.target.id)
                    if (element) element.classList.add("drag")
                    const selectedId = e.target.id.split("-")
                    this.selectedDay = selectedId[0]
                    this.selectedHour = selectedId[1]
                }
            }
        },

        stopSelectCell(e) {
            /**
             * For all $refs which are in e.target.id.slice(0, 1) column, remove .let-column-focus class
             *
             * @param {Object} e - event object
             * @return {void}
             * */

            for (let i = 0; i < this.days.length; i++) {
                if (
                    parseInt(e.target.id.slice(0, 1)) === this.days[i].value &&
                    this.$refs[`selected-cell-${this.days[i].value}`]
                ) {
                    for (
                        let j = 0;
                        j <
                        this.$refs[`selected-cell-${this.days[i].value}`]
                            .length;
                        j++
                    ) {
                        this.$refs[`selected-cell-${this.days[i].value}`][
                            j
                        ].classList.remove("let-column-focus")
                    }
                }
            }

            /**
             * If e.target.id exist, remove .drag class to e.target.id element and set selectedDay and selectedHour to have the end information
             *
             * @param {Object} e - event object
             * @return {void}
             */

            if (e.target.id) {
                const selectedId = e.target.id.split("-")
                const endDay = selectedId[0]
                const startHour =
                    parseInt(this.selectedHour) < parseInt(selectedId[1])
                        ? this.selectedHour
                        : selectedId[1]
                const endHour =
                    parseInt(this.selectedHour) < parseInt(selectedId[1])
                        ? selectedId[1]
                        : this.selectedHour

                if (parseInt(this.selectedDay) === parseInt(endDay)) {
                    const period = {
                        day: parseInt(endDay),
                        start: startHour,
                        end: endHour,
                    }
                    if (endHour === "48" || endHour === "47")
                        period.accurateEnd = "23:59"
                    this.periods.push(period)
                }
                this.fusionTimeRange()
                this.selectedDay = null
                this.selectedHour = null
                this.clearDrag()
            }
            window.getSelection()?.removeAllRanges()
        },
        selectCell(e) {
            if (this.selectedDay && this.selectedHour) {
                const element = document.getElementById(e.target.id)
                if (element) element.classList.add("drag")
            }
        },
        clearDrag() {
            let htmlCollection = null
            do {
                htmlCollection = document.getElementsByClassName("drag")
                for (let item of htmlCollection) {
                    item.classList.remove("drag")
                }
            } while (htmlCollection.length > 0)
        },
        setIfMatchHourCell(cell, start) {
            return cell === start
        },
        checkSelectedCell(timer, day, index_hour) {
            return (
                timer.day === day.value &&
                this.setIfMatchHourCell(
                    index_hour,
                    parseInt(timer.start),
                    parseInt(timer.end)
                )
            )
        },
        getStartPosition() {
            let element = document.getElementById(this.getIdFirstElement())
            let width = 0
            let height = 0

            if (element) {
                width = element.offsetWidth
                height = element.offsetHeight
            }
            return {
                width,
                height,
            }
        },
        getHeightTimer(day, cellHour, timerStartIndex, timerEndIndex) {
            let timerEnd = ""
            const timerStart = this.hours[timerStartIndex].time
            if (timerEndIndex === -2) {
                timerEnd = this.hours[this.hours.length - 1].time
            } else {
                timerEnd = this.hours[timerEndIndex].time
            }
            const dayColumn = document.getElementById("day-" + day)
            const minStart = parseInt(timerStart.split(":")[1])
            const minEnd = parseInt(timerEnd.split(":")[1])
            const startPosition = this.getStartPosition()
            let height = startPosition.height

            if (
                !(cellHour === "00:00:00" && timerEnd === "00:00:00") &&
                dayColumn
            ) {
                if (timerEnd === "00:00:00") timerEnd = "23:30:00"

                if (
                    timerEnd.split(":")[1] !== "00" &&
                    timerEnd.split(":")[1] !== "30"
                )
                    timerEnd = timerEnd.split(":")[0] + ":" + "30:00"

                const startCell = dayColumn.querySelector(
                    '.half-hour[data-value="' + cellHour + '"]'
                )
                const endCell = dayColumn.querySelector(
                    '.half-hour[data-value="' + timerEnd + '"]'
                )

                let numberOfHalfHour =
                    parseInt(endCell.id.split("-")[1]) -
                    parseInt(startCell.id.split("-")[1])
                height += numberOfHalfHour * startPosition.height

                if (
                    (((minStart > 7 && minStart < 23) ||
                        (minStart > 37 && minStart < 53)) &&
                        minEnd < 38 &&
                        minEnd > 22) ||
                    (minStart < 38 &&
                        minStart > 22 &&
                        minEnd < 23 &&
                        minEnd > 7) ||
                    (minStart < 8 &&
                        minStart > 0 &&
                        minEnd < 23 &&
                        minEnd > 7) ||
                    (minStart > 37 && minStart < 53 && minEnd == 0)
                )
                    height -= 4
                if (
                    (((minStart < 23 && minStart > 7) ||
                        (minStart > 37 && minStart < 53)) &&
                        minStart !== 30 &&
                        minEnd > 7 &&
                        minEnd < 23) ||
                    (minStart < 38 && minEnd > 22 && minEnd < 8) ||
                    (minStart == 30 && minEnd > 0 && minEnd < 8)
                )
                    height -= 14
                if (
                    ((minStart < 23 && minStart > 7) ||
                        (minStart > 37 && minStart < 53)) &&
                    minEnd > 0 &&
                    minEnd < 8
                )
                    height -= 29
                if (
                    (((minStart < 23 && minStart > 7) ||
                        (minStart > 37 && minStart < 53)) &&
                        minEnd > 51) ||
                    (minStart < 8 && minEnd > 37 && minEnd < 52) ||
                    (minStart < 38 &&
                        minStart > 22 &&
                        minEnd > 37 &&
                        minEnd < 53) ||
                    (minStart > 0 && minStart < 8 && minEnd > 37 && minEnd < 53)
                )
                    height += 8
                if (
                    ((minStart >= 0 && minStart < 8) ||
                        (minStart > 22 && minStart < 38)) &&
                    minEnd > 52 &&
                    minEnd <= 59
                )
                    height += startPosition.height
                if (minStart >= 8 && minStart < 23 && minEnd === 0) height -= 4
                if (minStart === 52 && minEnd === 0) height -= 6
                if (
                    (minStart >= 53 && minEnd === 0) ||
                    (minStart < 7 && minEnd > 0 && minEnd < 8)
                )
                    height -= startPosition.height
                if (timerEnd === "00:00:00") height += startPosition.height
            }
            return height
        },
        async resizeWindow() {
            this.displayCalendar = false
            await this.$nextTick
            this.displayCalendar = true
        },
        openModalEdit(
            day,
            dayName,
            index_timer,
            timer,
            isCallable,
            displayDelete = true
        ) {
            if (isCallable) {
                let start = this.hours[parseInt(timer.start)].time
                let end =
                    this.hours[parseInt(timer.end) + 1]?.time || "00:00:00"
                if (timer.accurateStart) {
                    start = timer.accurateStart
                }
                if (timer.accurateEnd) {
                    end = timer.accurateEnd
                }

                this.openIndex = index_timer
                this.openModal("edit-time-range", {
                    title: "edit-time-range",
                    days: this.days,
                    day,
                    start,
                    end,
                    displayDelete,
                    periodIsAlreadyCovered: (period) => {
                        return this.periodIsAlreadyCovered(period)
                    },
                    deleteTimeRange: () => {
                        this.deleteTimeRange()
                    },
                    editTimeRange: (payload) => {
                        this.editTimeRange(payload.start, payload.end)
                    },
                })
            }
        },
        deleteTimeRange(index = this.openIndex) {
            this.periods.splice(index, 1)
            this.getDifferentDay()
            this.closeModal()
        },
        // correspondence with time to display by 30 minutes
        searchValues(start, end) {
            let startSearch = ""
            let endSearch = ""
            let accurateStart = null
            let accurateEnd = null

            // if start hour > 9
            // ex: '10:00' -> '010:'
            if (parseInt(start.split(":")[0]) > 9) {
                startSearch = `0${start.split(":")[0]}:`
            } else {
                // if start hour < 9
                // ex: '09:00' -> '09:'
                startSearch = `${start.split(":")[0]}:`
            }
            // if start minutes is 0 or 30
            if (
                parseInt(start.split(":")[1]) === 0 ||
                parseInt(start.split(":")[1]) === 30
            ) {
                // concat current startSearch with minutes
                // ex: 10:00 -> 010:00:00
                startSearch += `${start.split(":")[1]}:00`
            } else if (parseInt(start.split(":")[1]) < 30) {
                // if start minutes inferior that 30
                // set up accurateStart with start value
                // ex: 09:15 -> 09:00:00
                accurateStart = `${start}`
                startSearch += `00:00`
            } else if (parseInt(start.split(":")[1]) > 30) {
                // if start minutes supperior that 30
                // set up accurateStart with start value
                // ex: 09:36 -> 09:00:00
                accurateStart = `${start}`
                startSearch += `30:00`
            }

            if (parseInt(end.split(":")[0]) > 9) {
                endSearch = `0${end.split(":")[0]}:`
            } else {
                endSearch = `${end.split(":")[0]}:`
            }
            if (
                parseInt(end.split(":")[1]) === 0 ||
                parseInt(end.split(":")[1]) === 30
            ) {
                endSearch += `${end.split(":")[1]}:00`
            } else if (parseInt(end.split(":")[1]) < 30) {
                accurateEnd = `${end}`
                endSearch += `00:00`
            } else if (parseInt(end.split(":")[1]) > 30) {
                const endAsArray = end.split(":")
                accurateEnd = `${end}`
                /**
                 * Hours with minutes > 30 must be rounded to superior hour
                 */
                endSearch = `${parseInt(end.split(":")[0]) < 10 ? "0" : ""}${
                    parseInt(endAsArray[0]) + 1
                }:00:00`
                if (parseInt(end.split(":")[0]) > 9) endSearch = `0${endSearch}`
            }

            const indexStart = this.hours.findIndex(
                (e) => startSearch === e.time
            )
            const indexEnd = this.hours.findIndex((e) => endSearch === e.time)

            return {
                accurateStart,
                accurateEnd,
                start: indexStart,
                end: indexEnd,
            }
        },
        editTimeRange(start, end) {
            this.closeModal()
            const getSearch = this.searchValues(start, end)
            const indexStart = getSearch.start
            const indexEnd = getSearch.end

            const oldValue = this.periods[this.openIndex]

            if (indexStart > 0 && indexEnd > 0) {
                this.periods[this.openIndex] = {
                    day: oldValue.day,
                    start: indexStart.toString(),
                    end: (indexEnd - 1).toString(),
                    accurateStart: getSearch.accurateStart,
                    accurateEnd: getSearch.accurateEnd,
                }
                this.fusionTimeRange()
            } else {
                let accurateStart = null
                let accurateEnd = null

                if (indexStart < 0) {
                    accurateStart = start
                }
                if (indexEnd < 0) {
                    accurateEnd = end
                }

                this.periods[this.openIndex] = {
                    day: oldValue.day,
                    start: indexStart.toString(),
                    end: (indexEnd - 1).toString(),
                }
                this.getDifferentDay()
            }
        },
        /**
         * Control if a period is already covered by an existing one
         * @param periodToAdd
         * @returns {boolean}
         */
        periodIsAlreadyCovered(periodToAdd) {
            if (!periodToAdd.start || !periodToAdd.end) return false
            const { start, end } = this.searchValues(
                periodToAdd.start,
                periodToAdd.end
            )

            const selectedDays = periodToAdd.selectedDays
            const index = this.periods.findIndex((existingPeriod) => {
                //
                return (
                    //if period A start before period B AND period A end before period B
                    selectedDays.includes(existingPeriod.day) &&
                    parseInt(existingPeriod.start) <= parseInt(start) &&
                    parseInt(existingPeriod.end) + 1 >= parseInt(end)
                    // ||
                    // // Start before Exisiting Period start AND end before Exisiting Period end
                    // (selectedDays.includes(existingPeriod.day) &&
                    //     parseInt(end) > parseInt(existingPeriod.start) &&
                    //     parseInt(end) <= parseInt(existingPeriod.end) + 1) ||
                    // // Start after Exisiting Period start AND end after Exisiting Period end
                    // (selectedDays.includes(existingPeriod.day) &&
                    //     parseInt(start) > parseInt(existingPeriod.start) &&
                    //     parseInt(start) <= parseInt(existingPeriod.end) + 1) ||
                    // // Start before Exisiting Period start AND end after Exisiting Period end
                    // (selectedDays.includes(existingPeriod.day) &&
                    //     parseInt(start) <= parseInt(existingPeriod.start) &&
                    //     parseInt(end) >= parseInt(existingPeriod.end) + 1)
                )
            })
            return index !== -1
        },
        fusionTimeRange() {
            const filterPeriods = []
            this.days.forEach((value, index) => {
                filterPeriods[index] = []
                this.periods.forEach((period) => {
                    if (period.day === index) {
                        filterPeriods[index].push({
                            start: period.start,
                            end: period.end,
                            accurateStart: period.accurateStart,
                            accurateEnd: period.accurateEnd,
                        })
                    }
                })
            })

            filterPeriods.forEach(() => {
                // by day
                filterPeriods.forEach((periods, day) => {
                    periods.forEach((detail, index) => {
                        /**
                         * Delete a period it this one already covered by
                         * another period, example :
                         * period A
                         * - start : 08:00
                         * - end : 15:00
                         * period B
                         * - start : 09:00
                         * - end : 14:00
                         * So period B is inside period A => delete period B
                         */
                        const indexDelete = periods.findIndex((e) => {
                            // if period A start before period B
                            return (
                                parseInt(detail.start) < parseInt(e.start) &&
                                // AND period A end before period B
                                parseInt(detail.end) >= parseInt(e.end)
                            )
                        })

                        /**
                         * Fusion a period if they overlap
                         * period A
                         * - start : 08:00
                         * - end : 11:00
                         * period B
                         * - start : 09:00
                         * - end : 14:00
                         */
                        const indexFusion = periods.findIndex(
                            (e) =>
                                // if period A start before or same time that period B
                                parseInt(detail.start) <= parseInt(e.start) &&
                                // and period A end after period B start - 30 minutes
                                parseInt(detail.end) > parseInt(e.start) - 1 &&
                                // and period A end before period B
                                parseInt(detail.end) < parseInt(e.end)
                        )

                        /**
                         * Combine periods if it's touching
                         * period A
                         * - start : 08:00
                         * - end : 11:00
                         * period B
                         * - start : 11:00
                         * - end : 14:00
                         */
                        const indexCombine = periods.findIndex(
                            (e) =>
                                parseInt(detail.end) === parseInt(e.start) - 1
                        )

                        if (indexDelete >= 0) {
                            filterPeriods[day].splice(indexDelete, 1)
                        } else if (indexFusion >= 0) {
                            filterPeriods[day][index] = {
                                start: detail.start,
                                end: periods[indexFusion].end,
                                accurateStart: detail.accurateStart,
                                accurateEnd: periods[indexFusion].accurateEnd,
                            }
                            filterPeriods[day].splice(indexFusion, 1)
                        } else if (indexCombine >= 0) {
                            filterPeriods[day][index] = {
                                start: detail.start,
                                end: periods[indexCombine].end,
                                accurateStart: detail.accurateStart,
                                accurateEnd: periods[indexCombine].accurateEnd,
                            }
                            filterPeriods[day].splice(indexCombine, 1)
                        }
                    })
                })
            })

            this.periods = []

            filterPeriods.forEach((values, index) => {
                values.forEach((e) => {
                    this.periods.push({
                        day: index,
                        start: e.start,
                        end: e.end,
                        accurateStart: e.accurateStart,
                        accurateEnd: e.accurateEnd,
                    })
                })
            })
        },
        async displayDay(id) {
            const index = this.displayDays.findIndex((e) => e === id)
            if (index >= 0) {
                this.displayDays.splice(index, 1)
            } else {
                this.displayDays.push(id)
            }
            await this.resizeWindow()
        },
        getIdFirstElement() {
            let start = 0
            if (this.displayDays.length > 0) {
                start = this.displayDays.sort()[0]
            }
            return `${start}-0`
        },
        modalCreate() {
            this.openModal("edit-time-range", {
                title: "create-time-range",
                displayCreate: true,
                days: this.days,
                differentDay: this.differentDay,
                periodIsAlreadyCovered: (period) => {
                    return this.periodIsAlreadyCovered(period)
                },
                createTimeRange: (payload) => {
                    this.createTimeRange(
                        payload.start,
                        payload.end,
                        payload.selectedDays
                    )
                },
            })
        },
        createTimeRange(start, end, days) {
            this.closeModal()
            const getSearch = this.searchValues(start, end)

            days.forEach((e, index) => {
                const object = {
                    day: index,
                    start: getSearch.start,
                    end:
                        getSearch.end === getSearch.start
                            ? getSearch.end
                            : getSearch.end - 1,
                    accurateStart: getSearch.accurateStart,
                    accurateEnd: getSearch.accurateEnd,
                }

                this.periods.push(object)
            })

            this.fusionTimeRange()
            this.periods = this.removeDuplicates(this.periods)
        },
        removeDuplicates(array) {
            return array.filter(
                (item, index, self) =>
                    index === self.findIndex((t) => deepEqual(item, t))
            )
        },
        copyDay(dayId) {
            this.openModal("copie-day-time-range", {
                title: "copie-day-time-range",
                days: this.days,
                selectedDay: dayId,
                saveCopy: (payload) => {
                    this.copySelectedDays(payload.days, payload.originDay)
                },
            })
        },
        copySelectedDays(days, origin) {
            const tempPeriod = this.periods
            const indexDelete = []
            tempPeriod.forEach((period, index) => {
                if (days[period.day]) {
                    indexDelete.push(index)
                }
            })
            indexDelete.sort(function (a, b) {
                return a - b
            })
            indexDelete.reverse()
            indexDelete.forEach((index) => {
                this.periods.splice(index, 1)
            })

            const periodsToAdd = []
            tempPeriod.forEach((period) => {
                if (period.day === origin) {
                    periodsToAdd.push(period)
                }
            })
            days.forEach((day, index) => {
                periodsToAdd.forEach((period) => {
                    this.periods.push({
                        day: index,
                        start: period.start,
                        end: period.end,
                        accurateStart: period.accurateStart,
                        accurateEnd: period.accurateEnd,
                    })
                })
            })
            this.getDifferentDay()
            this.closeModal()
        },
        openCopyModal() {
            this.openModal("copie-day-time-range", {
                title: "copie-day-time-range",
                days: this.days,
                saveCopy: (payload) => {
                    this.copySelectedDays(payload.days, payload.originDay)
                },
            })
        },
        openMaxHourModal() {
            this.openModal("alert", {
                title: this.$t("vigik.not-enough-time-slot"),
            })
        },
        razVerif() {
            this.$emit("razVerif")
        },
        saveVerif() {
            // set true to get around beforeRouteLeave
            this.$emit("saveVerif", true)
        },
        cancelVerif() {
            // set true to get around beforeRouteLeave
            this.$emit("cancelVerif", true)
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";
.let-column-focus {
    z-index: -1;
}

.week-calendar {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;

    @media all and (min-width: 768px) {
        .only-mobile {
            display: none;
        }

        .only-desktop {
            display: block;
        }
        .button-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: $small;

            .button-left-part-wrapper {
                display: flex;
                gap: 1em;
            }

            .button-right-part-wrapper {
                display: flex;
                gap: 1em;
            }
            .mobile-right-part {
                display: none;
            }
        }
    }

    @media all and (max-width: 768px) {
        .only-desktop {
            display: none;
        }

        .only-mobile {
            display: block;
        }

        .button-container {
            display: flex;
            justify-content: space-between;
            .button-left-part-wrapper {
                display: none;
                gap: 1em;
            }
            .button-right-part-wrapper {
                display: flex;
                gap: 1em;
            }
            .mobile-right-part {
                position: relative;
                display: flex;

                .mobile-menu {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5em;
                    position: absolute;
                    bottom: -4.6em;
                    right: 0;
                    background: $white;
                    border: $grey-light 1px solid;
                    padding: 10px 15px;
                    border-radius: 10px;

                    p {
                        margin: 0;
                        text-align: start;
                        text-wrap: nowrap;
                        font-size: $small;
                    }
                }
            }
        }
    }

    .calendar-week {
        display: flex;
        font-family: $font-avenir-book;
        color: $blue-lighter;
        font-size: $normal;
        width: 100%;
        height: 100%;
        @media all and (max-width: 768px) {
            height: 95%;
        }
        .hours {
            margin-top: 35px;
            width: max-content;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            align-content: flex-start;
            height: max-content;
            .hour {
                margin-right: 3px;
                height: 15px;
                padding: 2px;
                @media all and (max-width: 768px) {
                    padding: 2.2px;
                }
            }
            &.padding-border {
                padding-top: 5px;
            }
        }
        .calendar-cells {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            height: 100%;
            cursor: crosshair;
            &.readonly {
                cursor: not-allowed;
            }
            .days {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                align-content: center;
                padding-top: 10px;
                padding-bottom: 10px;
                .day {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    width: 100%;
                    height: 100%;
                    font-size: $small;
                    i {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        padding-left: 5px;
                        color: $blue-neutral;
                        cursor: pointer;
                        @media all and (max-width: 768px) {
                            font-size: $small;
                        }
                    }
                    span {
                        color: $blue-neutral;
                        &.only-mobile {
                            display: none;

                            @media all and (max-width: 768px) {
                                display: block;
                            }
                        }

                        &.no-mobile {
                            display: block;
                            @media all and (max-width: 768px) {
                                display: none;
                            }
                        }
                    }
                    & .selected-day {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        background-color: $orange-neutral;
                        height: 25px;
                        width: 25px;
                        border-radius: 20px;
                        color: $white;
                    }
                }
            }
            .calendar-days {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                align-content: center;
                background-color: rgba(255, 255, 255, 0.1);
                z-index: 1;
                .column-days {
                    width: 100%;
                    border-left: solid 0.5px $light-grey;
                    &:last-child {
                        border-right: solid 0.5px $light-grey;
                    }
                    .half-hour {
                        border-top: solid 0.5px $light-grey;
                        height: 15px;
                        .hour-hover {
                            display: none;
                            z-index: 0;
                            transition: all 0.2s;
                        }
                        &:last-child {
                            border-bottom: solid 0.5px $light-grey;
                        }
                        &.drag {
                            background-color: $grey-lighter;
                        }
                        &.disabled {
                            background-color: $grey-lighter;
                            cursor: not-allowed;
                        }
                        .time-range {
                            transition: all 0.2s;
                            .selected-cell {
                                width: 100%;
                                display: flex;
                                position: relative;
                                right: 0;
                                &:hover > .hour-details {
                                    display: flex;
                                }
                                .hour-details {
                                    display: none;
                                    flex-direction: row;
                                    justify-content: center;
                                    align-items: center;
                                    align-content: center;
                                    flex-wrap: wrap;
                                    gap: 5px;
                                    position: absolute;
                                    z-index: 1;
                                    padding: 5px;
                                    width: 100%;
                                    background-color: $white;
                                    border: solid 1px $blue-white;
                                    border-radius: 20px;
                                    font-size: $small;
                                }
                                .overlay {
                                    background-color: $grey-slate;
                                    border-radius: 10px;
                                    width: 100%;
                                    height: 100%;
                                    font-size: $normal;
                                    color: $blue-white;
                                    cursor: pointer;
                                    .actions {
                                        display: flex;
                                        justify-content: start;
                                        padding: 0.5em 1em;

                                        p {
                                            color: $white;
                                            margin: 0;

                                            @media all and (max-width: 480px) {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &:hover {
                            background-color: $grey-lighter;
                            .hour-hover {
                                display: flex;
                                position: relative;
                                transition: all 0.2s;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
